import { TableInputs } from '@unifii/components';
import { FilterValue, TableDataSource } from '@unifii/library/common';
import { Option, isArrayOfType, isOption, isString } from '@unifii/sdk';

import { WorkflowSummaryEntry } from './workflow-summary-table-manager';

export class WorkflowSummaryDatasource extends TableDataSource<WorkflowSummaryEntry> {

	filtered = false;
	sorted = false;

	private optionsFilters: [string, Option[]][];

	constructor(
		private entries: Promise<WorkflowSummaryEntry[]>,
		private inputs: TableInputs<FilterValue>,
	) {
		super();

		this.optionsFilters = Object.entries(this.inputs.filters ?? {})
			.filter((value: [string, FilterValue]): value is [string, Option[]] => isArrayOfType(value[1], isOption));
	}

	async load() {
		const data = await this.entries;

		// filter
		const filteredData = data.filter((entry) => {
			return this.optionsFilters.every(([key, filterOptions]) =>
				filterOptions.some((filterValue) => filterValue.identifier === entry[key as keyof WorkflowSummaryEntry]),
			);
		});

		// sort
		const sortName = this.inputs.sort?.name as keyof WorkflowSummaryEntry | undefined;

		if (!sortName || !['startState', 'targetState', 'section'].includes(sortName)) {
			this.stream.next({ data: filteredData });

			return;
		}

		filteredData.sort((a, b) => {
			let valueA = a[sortName] ?? '';
			let valueB = b[sortName] ?? '';

			if (isString(valueA) && isString(valueB)) {
				valueA = valueA.toLowerCase();
				valueB = valueB.toLowerCase();
			}

			if (valueA === valueB) {
				return 0;
			}

			const sortResult = valueA > valueB ? 1 : -1;

			return this.inputs.sort?.direction === 'desc' ? sortResult * -1 : sortResult;
		});

		this.stream.next({ data: filteredData });
	}

}
