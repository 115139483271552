import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RedirectCommand } from '@angular/router';
import { DataDescriptor, DataDescriptorService, ModalService } from '@unifii/library/common';
import { CompoundType, FieldType, UfError } from '@unifii/sdk';
import { Observable } from 'rxjs';

import { UcDefinition, UcField } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { FieldReferenceHelper } from 'helpers/field-reference-helper';
import { truncateStringWithEllipsis } from 'pages/utils';
import { definitionResolver } from 'resolvers/definition-resolver';
import { BreadcrumbService } from 'services/breadcrumb.service';

import { FormEditorCache, FormEditorCacheService } from '../form-editor-cache';
import { FORM_EDITOR_CONSTANTS } from '../form-editor-constants';

import { FormSummaryInfoModalComponent } from './form-summary-info-modal.component';

interface FormSummaryEntry {
	children: FormSummaryEntry[];
	icon?: string;
	identifier?: string;
	label?: string;
	shortLabel?: string;
	isRequired?: boolean;
	isReadOnly?: boolean;
	autofill?: string;
	bindTo?: string;
	showIf?: string;
	template?: string;
	width?: string;
	visibleTo?: string;
	tags: string[];
	variations?: boolean;
	validators?: boolean;
	help?: boolean;
	field?: UcField;
}

@Component({
	selector: 'uc-form-summary',
	templateUrl: './form-summary.html',
	styleUrls: ['./form-summary.less'],
	providers: [
		{ provide: FormEditorCache, useClass: FormEditorCacheService },
	],
	standalone: false,
})
export class FormSummaryComponent implements OnInit {

	@HostBinding('class.stretch-component') stretchComponentClass = true;

	protected error?: UfError;
	protected definition?: UcDefinition;
	protected entries: FormSummaryEntry[] = [];

	private route = inject(ActivatedRoute);
	private modalService = inject(ModalService);
	private dataDescriptor: DataDescriptor | undefined;
	private breadcrumbService = inject(BreadcrumbService);
	private builderHeaderService = inject(BuilderHeaderService);
	private dataDescriptorService = inject(DataDescriptorService);
	private cache = inject(FormEditorCache);

	ngOnInit() {
		const resolverData = this.route.snapshot.data.definition as Exclude<Awaited<ReturnType<typeof definitionResolver>>, Observable<unknown> | RedirectCommand>;

		if (resolverData instanceof UfError) {
			this.error = resolverData;

			return;
		}

		this.definition = resolverData;

		void this.setup();
	}

	protected rowClick(row: FormSummaryEntry) {
		void this.modalService.openMedium(FormSummaryInfoModalComponent, { field: row.field, cache: this.cache });
	}

	private mapIcon(type: FieldType) {
		return FieldReferenceHelper.getFieldReference({ type }, CompoundType.Form).icon;
	}

	private async setup() {
		if (!this.definition?.bucket) {
			return;
		}

		this.dataDescriptor = await this.dataDescriptorService.getBucketDataDescriptor(this.definition.bucket);

		this.entries = (this.definition.fields ?? []).map(this.buildFormSummaryEntry.bind(this));

		const title = 'Summary';

		const breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [title]);

		// add fake breadcrumb back to form
		breadcrumbs.splice(breadcrumbs.length - 1, 0, { name: this.definition.label, urlSegments: ['../'] });

		this.builderHeaderService.buildConfig({
			title,
			...this.definition,
			breadcrumbs,
			hideSaveButton: true,
			cancelRoute: ['../../'],
		});
	}

	private buildFormSummaryEntry(field: UcField): FormSummaryEntry {
		const formSummaryEntry: FormSummaryEntry = {
			identifier: field.identifier,
			icon: this.mapIcon(field.type),
			label: truncateStringWithEllipsis(field.label ?? field.help ?? field.type, FORM_EDITOR_CONSTANTS.FIELD_RENDERED_LABEL_MAX_LENGTH),
			shortLabel: field.shortLabel,
			isRequired: field.isRequired,
			isReadOnly: field.isReadOnly,
			autofill: field.autofill,
			bindTo: field.bindTo,
			width: field.width?.toString(),
			showIf: field.showIf,
			tags: field.tags ?? [],
			variations: !!field.variations?.length,
			validators: !!field.validators?.length,
			help: !!field.help && field.type !== FieldType.Content,
			template: field.template,
			visibleTo: field.visibleTo?.split(',').join(', '),
			children: (field.fields ?? []).map(this.buildFormSummaryEntry.bind(this)),
			field,
		};

		const identifier = field.identifier;

		if (this.dataDescriptor && [FieldType.Lookup, FieldType.Choice, FieldType.MultiChoice, FieldType.Repeat].includes(field.type) && identifier) {
			const propertyDescriptors = this.dataDescriptor.propertyDescriptors.filter((propertyDescriptor) => propertyDescriptor.identifier.match(`\\b${identifier}\\.\\b`));

			formSummaryEntry.children = formSummaryEntry.children.concat(
				propertyDescriptors.map((propertyDescriptor) => ({
					children: [],
					icon: this.mapIcon(propertyDescriptor.type),
					tags: [],
					type: propertyDescriptor.type,
					identifier: propertyDescriptor.identifier,
					label: propertyDescriptor.display,
				})),
			);
		}

		return formSummaryEntry;
	}

}
