export * from './bucket-data.component';
export * from './bucket-field-settings.component';
export * from './bucket.component';
export * from './form-data.component';
export * from './metadata-modal.component';
export * from './bucket-settings.component';
export * from './buckets.component';
export * from './workflow-summary-table';

import { BucketDataComponent } from './bucket-data.component';
import { BucketFieldSettingsComponent } from './bucket-field-settings.component';
import { BucketSettingsComponent } from './bucket-settings.component';
import { BucketComponent } from './bucket.component';
import { BucketsComponent } from './buckets.component';
import { FormDataComponent } from './form-data.component';
import { MetadataModalComponent } from './metadata-modal.component';
import { WorkflowSummaryTableComponent } from './workflow-summary-table';

export const FORM_DATA_COMPONENTS = [
	BucketDataComponent, BucketFieldSettingsComponent, BucketComponent, BucketsComponent, FormDataComponent, MetadataModalComponent, BucketSettingsComponent, WorkflowSummaryTableComponent,
];
