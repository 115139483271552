import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RedirectCommand } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, DataLoaderFactory } from '@unifii/library/common';
import { UfError, hasLengthExact } from '@unifii/sdk';
import { Observable } from 'rxjs';

import { BuilderHeaderService } from 'components';
import { bucketDefinitionsResolver } from 'resolvers/bucket-definitions-resolver';
import { BreadcrumbService } from 'services/breadcrumb.service';

import { WorkflowSummaryTableManager } from './workflow-summary-table-manager';

@Component({
	providers: [
		DataLoaderFactory,
		{ provide: TableContainerManager, useClass: WorkflowSummaryTableManager },
	],
	template: `
        <div ufMasterDetail>
            <uf-table-container [class.pad-none]="!breadcrumbs" ufSyncRoute class="accent list-md">
				@if(breadcrumbs){
					<uf-breadcrumbs [breadcrumbs]="breadcrumbs" title class="large" />
				}
			</uf-table-container>
        </div>
        <router-outlet />
    `,
	standalone: false,
})
export class WorkflowSummaryTableComponent implements OnInit {

	protected breadcrumbs: Breadcrumb[] | undefined;

	private route = inject(ActivatedRoute);
	private breadcrumbService = inject(BreadcrumbService);
	private builderHeaderService = inject(BuilderHeaderService);

	ngOnInit() {

		const definitions = this.route.snapshot.data.bucketDefinitions as Exclude<Awaited<ReturnType<typeof bucketDefinitionsResolver>>, Observable<unknown> | RedirectCommand>;

		if (definitions instanceof UfError) {
			return;
		}

		const title = 'Workflow Summary';

		const breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [title]);

		// if under form, add fake breadcrumb back to form and display breadcrumbs
		if (hasLengthExact(definitions, 1)) {
			breadcrumbs.splice(breadcrumbs.length - 1, 0, { name: definitions[0].label, urlSegments: ['../'] });
			this.breadcrumbs = breadcrumbs;
		}

		this.builderHeaderService.buildConfig({
			title,
			breadcrumbs,
			hideSaveButton: true,
			cancelRoute: hasLengthExact(definitions, 1) ? ['../../'] : undefined,
		});
	}

}
