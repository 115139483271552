import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, RedirectCommand } from '@angular/router';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { DataDisplayCodeValue, DataDisplayIconValue, DataDisplayRouterLinkValue, FilterEntry, FilterType, FilterValue, HierarchyUnitProvider, TableConfig, TableConfigColumn, fieldIterator } from '@unifii/library/common';
import { Option, UfError, hasLengthAtLeast, isArrayOfType, isString } from '@unifii/sdk';
import { Observable, Subject } from 'rxjs';

import { ActivityType, FormSubmittedCondition, UcDefinition, UcField, UcTransition, UcWorkflow, WorkflowEventType, WorkflowRule, WorkflowRuleActivity } from 'client';
import { UrlSegments } from 'constant';
import { bucketDefinitionsResolver } from 'resolvers/bucket-definitions-resolver';

import { WorkflowSummaryDatasource } from './workflow-summary-datasource';

export interface WorkflowSummaryEntry {
	startState: string;
	targetState: string;
	section: string;
	action: string;
	buttonLabel: string;
	result?: string;
	validate: boolean;
	alwaysVisible: boolean;
	keepOpen: boolean;
	showIf?: string;
	role?: string[];
	workflowRules: {
		name: string;
		route: string[];
	}[];
	activities: {
		name: string;
		route: string[];
	}[];
	form: string;
}

@Injectable()
export class WorkflowSummaryTableManager implements TableContainerManager<WorkflowSummaryEntry, FilterValue, FilterEntry> {

	tableConfig: TableConfig<WorkflowSummaryEntry>;
	inputManager: TableInputManager<FilterValue, FilterEntry>;
	update = new Subject<TableInputs<FilterValue>>();

	private workflowEntries: Promise<WorkflowSummaryEntry[]>;
	private definitions: UcDefinition[] | undefined;
	private ucWorkflow = inject(UcWorkflow);
	private route = inject(ActivatedRoute);

	constructor() {

		const resolverData = this.route.snapshot.data.bucketDefinitions as Exclude<Awaited<ReturnType<typeof bucketDefinitionsResolver>>, Observable<unknown> | RedirectCommand>;

		if (resolverData instanceof UfError) {
			return;
		}

		this.definitions = resolverData;

		this.inputManager = new TableInputManager(undefined, inject(HierarchyUnitProvider), null, null);

		// eslint-disable-next-line sonarjs/no-async-constructor
		this.workflowEntries = this.getWorkflowEntries();

		this.tableConfig = this.getTableConfig();
	}

	/** Synchronous, but entries are asynchronously loaded */
	createDataSource(inputs: TableInputs<FilterValue> = {}): WorkflowSummaryDatasource {
		return new WorkflowSummaryDatasource(this.workflowEntries, inputs);
	}

	private updateFilterEntries(workflowEntries: WorkflowSummaryEntry[]) {

		// Start State filter
		const filters: FilterEntry[] = [
			{
				label: 'Start State',
				identifier: 'startState',
				type: FilterType.OptionArray,
				options: this.optionsMapper(workflowEntries, 'startState'),
			},
			{
				identifier: 'targetState',
				label: 'Target State',
				type: FilterType.OptionArray,
				options: this.optionsMapper(workflowEntries, 'targetState'),
			},
			{
				identifier: 'section',
				label: 'Section',
				type: FilterType.OptionArray,
				options: this.optionsMapper(workflowEntries, 'section'),
			},
			{
				identifier: 'role',
				label: 'Roles',
				type: FilterType.OptionArray,
				options: this.optionsMapper(workflowEntries, 'role'),
			},
		];

		if (this.definitions && hasLengthAtLeast(this.definitions, 2)) {
			filters.push({
				identifier: 'form',
				label: 'Form',
				type: FilterType.OptionArray,
				options: this.optionsMapper(workflowEntries, 'form'),
			});
		}

		this.inputManager.filterManager.entries = filters;
	}

	private optionsMapper(entries: WorkflowSummaryEntry[], key: keyof WorkflowSummaryEntry): Option[] | undefined {
		const rawValues = entries.flatMap((entry) => {
			const value = entry[key];

			if (isString(value)) {
				return [value];
			}

			if (isArrayOfType(value, isString)) {
				return value;
			}

			return [];
		});

		// Deduplicate all values
		const uniqueValues = Array.from(new Set(rawValues));

		return uniqueValues.map((v) => ({
			identifier: v,
			name: v,
		}));
	}

	private async getWorkflowEntries(): Promise<WorkflowSummaryEntry[]> {

		if (!this.definitions || !hasLengthAtLeast(this.definitions, 1)) {
			return [];
		}

		const bucketRules = await this.ucWorkflow.getRules({ params: { bucket: this.definitions[0].bucket, eventType: WorkflowEventType.FormSubmitted, limit: 1000 } });

		const entries = this.definitions.flatMap((definition) =>
			this.buildDefinitionWorkflowSummary(definition, bucketRules),
		);

		this.updateFilterEntries(entries);

		return entries;
	}

	private getTableConfig(): TableConfig<WorkflowSummaryEntry> {

		const columns: TableConfigColumn<WorkflowSummaryEntry>[]= [{
			name: 'startState',
			label: 'Start State',
			value: (item) => item.startState ? {
				code: item.startState,
			} satisfies DataDisplayCodeValue : null,
			sortable: true,
		}, {
			name: 'targetState',
			label: 'Target State',
			value: (item) => item.targetState ? {
				code: item.targetState,
			} satisfies DataDisplayCodeValue : null,
			sortable: true,
		}, {
			name: 'section',
			label: 'Section',
			sortable: true,
		}, {
			name: 'action',
			label: 'Action',
			value: (item) => item.action ? {
				code: item.action,
			} satisfies DataDisplayCodeValue : null,
		}, {
			name: 'buttonLabel',
			label: 'Button Label',
		}, {
			name: 'result',
			label: 'Result',
			value: (item) => item.result ? {
				code: item.result,
			} satisfies DataDisplayCodeValue : null,
		}, {
			name: 'validate',
			label: 'Validate',
			value: (item) => item.validate ? {
				icon: 'radioTick',
				colour: 'success',
			} satisfies DataDisplayIconValue : null,
		}, {
			name: 'alwaysVisible',
			label: 'Always Visible',
			value: (item) => item.alwaysVisible ? {
				icon: 'radioTick',
				colour: 'success',
			} satisfies DataDisplayIconValue : null,
		}, {
			name: 'keepOpen',
			label: 'Keep Open',
			value: (item) => item.keepOpen ? {
				icon: 'radioTick',
				colour: 'success',
			} satisfies DataDisplayIconValue : null,
		}, {
			name: 'showIf',
			label: 'Show If',
			value: (item) => item.showIf ? {
				code: item.showIf,
			} satisfies DataDisplayCodeValue : null,
		}, {
			name: 'role',
			label: 'Role',
			value: (item) => item.role?.join(', '),
		}, {
			name: 'workflowRules',
			label: 'Workflow Rules',
			value: (item) => item.workflowRules.map((rule) => {
				return {
					label: rule.name,
					routerLink: rule.route,
					style: 'lozenge',
				} satisfies DataDisplayRouterLinkValue;
			}),
		}, {
			name: 'activities',
			label: 'Activities',
			value: (item) => item.activities.map((activity) => {
				return {
					label: activity.name,
					routerLink: activity.route,
					style: 'lozenge',
				} satisfies DataDisplayRouterLinkValue;
			}),
		}];

		if (this.definitions && hasLengthAtLeast(this.definitions, 2)) {
			columns.push({
				name: 'form',
				label: 'Form',
				value: (item) => item.form ? {
					code: item.form,
				} satisfies DataDisplayCodeValue : null,
			});
		}

		return {
			pageSize: -1,
			cardExpands: false,
			columnToggles: true,
			columns,
		};
	}

	private buildDefinitionWorkflowSummary(definition: UcDefinition, bucketRules: WorkflowRule[]): WorkflowSummaryEntry[] {

		const entries: WorkflowSummaryEntry[] = [];

		const fields = [...fieldIterator(definition.fields)].map((field) => field.field);

		for (const field of fields) {
			for (const fieldTransition of field.transitions ?? []) {

				const rules: WorkflowRule[] = bucketRules.filter((rule) => {
					const condition = rule.condition as FormSubmittedCondition;

					if (condition.forms && !condition.forms.includes(definition.identifier)) {
						return false;
					}

					return condition.transitions?.some((ruleTransition) => ruleTransition.action === fieldTransition.action && ruleTransition.source === fieldTransition.source);
				});

				const activities : WorkflowRuleActivity[] = rules.flatMap((rule) => rule.activities);

				entries.push(this.buildWorkflowSummaryEntry(fieldTransition, field, rules, activities, definition.identifier));
			}
		}

		return entries;
	}

	private buildWorkflowSummaryEntry(transition: UcTransition, field: UcField, rules: WorkflowRule[], activities: WorkflowRuleActivity[], form: string): WorkflowSummaryEntry {
		return {
			startState: transition.source,
			targetState: transition.target,
			section: field.label ?? '',
			action: transition.action,
			buttonLabel: transition.actionLabel,
			showIf: transition.showIf,
			role: transition.role?.split(',') ?? [],
			keepOpen: !!transition.keepOpen,
			validate: !!transition.validate,
			result: transition.result,
			alwaysVisible: transition.hasPersistentVisibility ?? false,
			workflowRules: rules.map((rule) => ({ route: ['../../../', UrlSegments.Workflows, UrlSegments.WorkflowRules, rule.id], name: `${rule.consoleName}` })),
			activities: activities.map((activity) => ({ route: this.getActivityRoute(activity), name: `${activity.consoleName}` })),
			form,
		};
	}

	private getActivityRoute(activity: WorkflowRuleActivity): string[] {
		switch (activity.type) {
			case ActivityType.DataForwarder:
				return ['../../../', UrlSegments.Workflows, UrlSegments.WorkflowDataForwarders, activity.id];
			case ActivityType.FormData:
				return ['../../../', UrlSegments.Workflows, UrlSegments.FormData, activity.id];
			case ActivityType.Notification:
				return ['../../../', UrlSegments.Workflows, UrlSegments.WorkflowNotifications, activity.id];
			case ActivityType.Timer:
				return ['../../../', UrlSegments.Workflows, UrlSegments.WorkflowTimers, activity.id];
		}
	}

}
