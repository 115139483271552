import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { UfError } from '@unifii/sdk';

import { UcDefinition, UcProject } from 'client';

/**
 * @description
 * Ensures that minimum settings are provided to WorkflowSummary component to initialize
 */
export const bucketDefinitionsResolver: ResolveFn<UcDefinition[] | UfError> = (route: ActivatedRouteSnapshot) =>
	new DefinitionResolver(
		inject(UcProject),
	).resolve(route);

class DefinitionResolver {

	constructor(
		private ucProject: UcProject,
	) { }

	resolve(route: ActivatedRouteSnapshot): Promise<UcDefinition[] | UfError> {
		const { id, definitionId } = route.params;

		if (definitionId) {
			return this.ucProject.getForm(definitionId).then((definition) => [definition]);
		}

		return this.getBucketDefinitions(id);
	}

	private async getBucketDefinitions(bucketId: string): Promise<UcDefinition[]> {
		const forms = await this.ucProject.getForms({ params: { bucket: bucketId } });

		return Promise.all(forms.map((form) => this.ucProject.getForm(form.id)));
	}

}
